import React from 'react';

function Features(){
    return <section id="features">
        <div className="container">
            <div className="row">

            <div className="col-lg-4 feature-box">
                  
                    <svg xmlns="http://www.w3.org/2000/svg"  height="78" width="84"  viewBox="0 0 576 512">
                    <path fill="#63E6BE" d="M547.6 103.8L490.3 13.1C485.2 5 476.1 0 466.4 0L109.6 0C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9c0 0 0 0-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3L448 384l-320 0 0-133.4c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3L64 384l0 64c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-64 0-131.4c-4 1-8 1.8-12.3 2.3z"/></svg>
                    <h3>Sistema Completo</h3>
                    <p>Sistemas para Supermercados,Distribuidores,Agropecuária, Atacadistas,Industria, Bares, Restaurantes,Pizzarias,
                        Ferragista,panificadoras, Varejo em geral,
                        Força de venda, entre outros,
                        atende a legislação vigente para empresas do MEI, simples nacional, lucro Real e presumido</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <svg xmlns="http://www.w3.org/2000/svg"  height="78" width="84" viewBox="0 0 576 512">
                    <path fill="#63E6BE" d="M163.9 136.9c-29.4-29.8-29.4-78.2 0-108s77-29.8 106.4 0l17.7 18 17.7-18c29.4-29.8 77-29.8 106.4 0s29.4 78.2 0 108L310.5 240.1c-6.2 6.3-14.3 9.4-22.5 9.4s-16.3-3.1-22.5-9.4L163.9 136.9zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5L192 512 32 512c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l36.8 0 44.9-36c22.7-18.2 50.9-28 80-28l78.3 0 16 0 64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0-16 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l120.6 0 119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384c0 0 0 0 0 0l-.9 0c.3 0 .6 0 .9 0z"/></svg>
                    <h3>Sistemas sem complicação</h3>                    
                    <p>O sistema possui uma interface intuitiva e amigável, telas padronizadas que tornam a navegação pelo sistema fácil e agradável,
                        Relatórios e Dashboards.
                        Conseguindo Aumento da Produtividade, menos erros e adaptação Rápida.
                    </p>
                </div>
                
                <div className="col-lg-4 feature-box">
                    <svg xmlns="http://www.w3.org/2000/svg"  height="78" width="84" viewBox="0 0 576 512">
                    <path fill="#63E6BE" d="M512 80c8.8 0 16 7.2 16 16l0 32L48 128l0-32c0-8.8 7.2-16 16-16l448 0zm16 144l0 192c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16l0-192 480 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24l48 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-48 0zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-112 0z"/></svg>
                    <h3>Meios de Pagamento</h3>                    
                    <p>Integração com os principais meios de pagamento como TEF Dedicado, PIX e Maquininhas Stone, com isto oferecemos mais agilidade e segurança com os meios de pagamento,
                       tenha um PDV inteligente e integrado.
                    </p>
                </div>
                
            </div>
            <div className="row">

                <div className="col-lg-4 feature-box">
                    
                    <svg xmlns="http://www.w3.org/2000/svg" height="78" width="84" viewBox="0 0 512 512">
                    <path fill="#63E6BE" d="M57.7 193l9.4 16.4c8.3 14.5 21.9 25.2 38 29.8L163 255.7c17.2 4.9 29 20.6 29 38.5l0 39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9l0 39c0 15.6 14.9 26.9 29.9 22.6c16.1-4.6 28.6-17.5 32.7-33.8l2.8-11.2c4.2-16.9 15.2-31.4 30.3-40l8.1-4.6c15-8.5 24.2-24.5 24.2-41.7l0-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1L257 256c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2c-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5c4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5 .3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c-3.5-.2-6.9-.3-10.4-.3C163.1 48 84.4 108.9 57.7 193zM464 256c0-36.8-9.6-71.4-26.4-101.5L412 164.8c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3c1.2-9 1.8-18.2 1.8-27.5zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
                    <h3>Integração com Scanntech, Ifood, CRM Bonus e Loja Integrada</h3>
                    <p>Buscando sempre estar com as principais tecnologias do mercado os Sistemas Siscomsoft possui integração 
                        com Scanntech um clube de promoções,
                        Ifood para bares e restaurantes, CRM Bonus para fidelização de clientes, Loja integrada para vendas on-line.
                        Agilize o recebimento de pedidos
                        evitando erros e reprocesso, fidelize seus clientes, aumente suas vendas com sua loja online e 
                        controle seu estoque, agilize o cadastro de produtos e pedidos, 
                        emissão de notas.

                    </p>
                </div>

                <div className="col-lg-4 feature-box">
                    <svg xmlns="http://www.w3.org/2000/svg" height="78" width="84" viewBox="0 0 512 512">
                    <path fill="#63E6BE" d="M32 32c17.7 0 32 14.3 32 32l0 336c0 8.8 7.2 16 16 16l400 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L80 480c-44.2 0-80-35.8-80-80L0 64C0 46.3 14.3 32 32 32zM160 224c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32zm128-64l0 160c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-160c0-17.7 14.3-32 32-32s32 14.3 32 32zm64 32c17.7 0 32 14.3 32 32l0 96c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-96c0-17.7 14.3-32 32-32zM480 96l0 224c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-224c0-17.7 14.3-32 32-32s32 14.3 32 32z"/></svg>
                    <h3>Relatórios e Gráficos</h3>
                    <p>Diversos relatórios e Graficos intuitivos para auxiliar na tomada de decisão,
                        Meta de vendedores,Comissão de vendedores, lucratividade por produto e cliente, ticket médio, 
                        curva abc, ,equilibrio financeiro, DRe, Inadimplência de clientes,
                        etiqueta de produtos e clientes, romaneio de cargas, etiquetas personlizadas ...
                    </p>
                </div>
                <div className="col-lg-4 feature-box">
                    
                <svg xmlns="http://www.w3.org/2000/svg" height="78" width="84" viewBox="0 0 512 512">
                <path fill="#63E6BE" d="M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zm64 64l0 256 160 0 0-256L64 160zm384 0l-160 0 0 256 160 0 0-256z"/></svg>
                    <h3>Sistemas especificos</h3>
                    <p>Quer desenvolver seu negócio mas não sabe como? Precisa de ajuda para planejar ou executar o seu próximo projeto? Deixe-nos ajudá-lo.
                         Qualquer empresa pode progredir a partir de pequenas mudanças, mas construir um futuro neste ambiente em rápida evolução, significa assumir riscos e tomar decisões perspicazes.</p>
                </div>
                
               
                         
            </div>
            <div className="row">
            <div className="col-lg-4 feature-box">
                <svg xmlns="http://www.w3.org/2000/svg" height="78" width="84" viewBox="0 0 576 512">
                <path fill="#63E6BE" d="M96 80c0-26.5 21.5-48 48-48l288 0c26.5 0 48 21.5 48 48l0 304L96 384 96 80zm313 47c-9.4-9.4-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L409 161c9.4-9.4 9.4-24.6 0-33.9zM0 336c0-26.5 21.5-48 48-48l16 0 0 128 448 0 0-128 16 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48L48 480c-26.5 0-48-21.5-48-48l0-96z"/></svg>
                
                    <h3>Guia de validação de sistemas computadorizados</h3>                    
                    <p>Siscomsoft já esta atendendo aos requisitos técnicos do Guia de Validação de Sistemas Computadorizados – Guia Nº 33/2020 
                       Venha conhecer nossa solução para Distribuidoras de Medicamentos </p>
                </div>

            </div>
        </div>
    </section>;
  }

export default Features;