
import React from 'react';

function Banner() {
    return <section id="banner">
        <div className="container">
            <div className="row">

                <div className="col-lg-6">
                    <h1>ERP completo, flexível, simples de usar,Empresas do lucro Real/Presumido, Simples Nacional , MEI.
                        Atendemos Supermercados,Distribuidoras em geral,Distribuidora de Medicamentos(Guia 33),
                         Agropecuária, Atacadistas, Bares, Restaurantes, Panificadoras,
                         Pizzarias,Lojas de conveniência,Auto Peças, varejo em geral, entre outros...!</h1>
                    <h4>Gerencie seu negocio com segurança e agilidade!</h4>

                </div>

                <div className="col-lg-6">
                    <img alt="img" src="Images/screenshot-crm.png" />
                </div>

            </div>
        </div>
    </section>;

}

export default Banner;